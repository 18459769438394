import React from 'react';
import AdminSortingBlock from 'components/blocks/admin-sorting-block/admin-sorting-block';
import PageLayout from 'components/pageLayout';
import { Helmet } from 'react-helmet';

const AdminPanelBlockPage = (props) => {

    // const searchParams = new URLSearchParams(props.location.search);
    // const searchText = searchParams.get('searchText')
    // const page = searchParams.get('page')

    return (
    <>
    <Helmet
    title='Sorting'>

    </Helmet>
        <PageLayout>
            <AdminSortingBlock/>
        </PageLayout>
    </>
    );
};

export default AdminPanelBlockPage;
